import { default as _91slug_93r3mdishQfaMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93P7yCdVBZ8IMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93N7nOl3bPgfMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93UhltDDCiBvMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93flahFtNMjRMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93ohcxFAnCFAMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91slug_93qvvFnbzlodMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91tab_93kbCh6l100SMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue?macro=true";
import { default as _91id_93Xju8bL3bRXMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93buvqyN6PNtMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue?macro=true";
import { default as _91slug_93M34eoKq4GPMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91name_930X0bzdcVcpMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue?macro=true";
import { default as _91ocr_93aANQVYAekXMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93q65oem64UiMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93mJTIKNTM1EMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93Mh5pdjMuKiMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91jwt_93OyZO0gl4gZMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93KPVDHkmkYiMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexiPr3CNh8f9Meta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as byebyeie9LtFYd2T4hMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as gruppresorEi39yYdvoKMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue?macro=true";
import { default as chartersearchfJmP933a5vMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue?macro=true";
import { default as complaint7AReDGif1wMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as indexY4SxNAjrLXMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue?macro=true";
import { default as dinbokning_45aktuellbZ7EWdtsa7Meta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as faq6mWcvTODemMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as indexDrVNqHrDWgMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue?macro=true";
import { default as _91id_93OeiyRlXDxGMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indext7pl7cTmqWMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as indexkznM2unxepMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue?macro=true";
import { default as _91name_932GXuhP9d6eMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indextsHpKI9KE9Meta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexklQFO2l2fhMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/index.vue?macro=true";
import { default as dinbokningVCC23jp39TMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterQoqz1AUOHQMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineYI7LppDT66Meta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as packagessearchv9qbDFvSNwMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue?macro=true";
import { default as failedxSIKZU53HVMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexB2lw5wGrlsMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchC8mnwMJiw0Meta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexKGMABbNZX0Meta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45doneV75bcmoAdXMeta } from "/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-id",
    path: "/destination/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "campaign",
    path: "/campaign",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93KPVDHkmkYiMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "campaign-name",
    path: "/campaign/:name()",
    meta: _91name_930X0bzdcVcpMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign-gruppresor",
    path: "/campaign/gruppresor",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue")
  },
  {
    name: "chartersearch",
    path: "/sok-charter",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaint7AReDGif1wMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-id-tab",
    path: "/destination/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "destination",
    path: "/destination",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "favourites",
    path: "/favourites",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotel-id-tab",
    path: "/hotel/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_932GXuhP9d6eMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93qvvFnbzlodMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_93ohcxFAnCFAMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93flahFtNMjRMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "packagessearch",
    path: "/sok-packages",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93N7nOl3bPgfMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45doneV75bcmoAdXMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-03-0759/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]